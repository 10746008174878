import {IPopularGames} from "../../../lib/types/home";
import {IPopularGamesUS} from "../../../lib/types/home.us";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {NextArrowV2, PrevArrowV2} from "../../common/ArrowComponent/ArrowComponentV2";
import Slider from "react-slick";
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import styles from "./PopularGamesComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const PopularGamesSliderDesktop = (popularGamesData: IPopularGames | IPopularGamesUS) => {
    let selectedIndex = 0

    const router = useRouter()
    const [sliderSettings, setSliderSettings] = useState({
        className: "popularGamesSliderContainer",
        centerMode: false,
        infinite: false,
        centerPadding: "0px",
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        nextArrow: <NextArrowV2 onClick/>,
        prevArrow: <PrevArrowV2 onClick/>,
        speed: 500
    })

    useEffect(() => {
        setSliderSettings({...sliderSettings, infinite: true, centerMode: true})
    }, [])

    const sliderBeforeChange = (current: number, next: number) => {
        selectedIndex = next
        const popularGamesAnimLink = document.getElementById("popularGamesAnimLink")
        const popularGamesDataLink = document.getElementById("popularGamesDataLink")
        const popularGamesDataHeading = document.getElementById("popularGamesDataHeading")
        const popularGamesDataDesc = document.getElementById("popularGamesDataDesc")
        if (popularGamesAnimLink && popularGamesDataLink && popularGamesDataHeading && popularGamesDataDesc) {
            (popularGamesAnimLink as HTMLAnchorElement).href = popularGamesData.games[next].url;
            (popularGamesDataLink as HTMLAnchorElement).href = popularGamesData.games[next].url;
            (popularGamesDataHeading as HTMLParagraphElement).innerHTML = popularGamesData.games[next].name;
            (popularGamesDataDesc as HTMLParagraphElement).innerHTML = popularGamesData.games[next].about
        }
    }

    const handleCenterGameClick = (id: number | undefined) => {
        if (typeof id !== "undefined") {
            if (selectedIndex === Number(id)) {
                router.push(popularGamesData.games[selectedIndex].url)
            }
        } else {
            router.push(popularGamesData.games[selectedIndex].url)
        }
    }

    return (
        <>
            <Slider {...sliderSettings}
                    beforeChange={(current: number, next: number) => sliderBeforeChange(current, next)}>
                {
                    popularGamesData.games.map((item, idx) => {
                        return (
                            <ButtonClickAnimation key={"popular_games_" + idx}>
                                <div className={multipleClassName("slideImage", styles.slideImage)}
                                     onClick={(e) => handleCenterGameClick(idx)}
                                     id={"popularGamesAnimLink"}>
                                    <CustomImageComponent src={item.src}
                                                          alt={item.name}
                                                          layout={"fill"}
                                                          objectPosition={"bottom"}
                                                          objectFit={"contain"}/>
                                </div>
                            </ButtonClickAnimation>
                        )
                    })
                }
            </Slider>
            <div className={styles.gameContent}>
                <div onClick={(e) => handleCenterGameClick(undefined)}
                     id={"popularGamesDataLink"}>
                    <div className={styles.gameContentHeading}>
                        <p id={"popularGamesDataHeading"}>{popularGamesData.games[0].name}</p>
                    </div>
                </div>

                <div
                    className={styles.gameContentDesc}>
                    <p id={"popularGamesDataDesc"}>{popularGamesData.games[0].about}</p></div>
            </div>
        </>
    )
}

export default PopularGamesSliderDesktop